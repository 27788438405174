
import { Grid, Typography, Button, Stack, Card, Alert, } from "@suid/material";

import { AccountMenu } from '../components/AppMenuBar/AppBarMenu';
import { OrganizationCard } from '../components/SmallComponents/SmallOrganizationCard'
import { CandidatePings } from '../components/SmallComponents/SmallPingCandidate'

import { ShowCustomerMenu, ShowCandidateMenu } from '../components/AppMenuBar/AppBarQuickLaunch'
import { CardWithMinimize } from '../components/AppMenuBar/CardList'
import { isExtraSmall, isSmall, isMedium } from "../../utils/sizeUtil"

import { CustomerToDosComponent } from '../components/Customer/CustomerToDosComponent'

import { CandidateToDosComponent } from '../components/Candidate/CandidateToDosComponent'
import { getText, } from '../components/SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'

const HomePage = () => {

    const navigate = useNavigate();

    if (!isLoggedIn()) {
        navigate('/login');
        return;
    }

    const logoUrl1 = "/assets/imgs/Audi_logo_PNG3.png";
    const logoUrl2 = "/assets/imgs/CocaCola.png";
    const txtWorkAsCustomer = getText("quicklauncher", "workascustomer")
    const txtWorkAsCandidate = getText("quicklauncher", "workascandidate")
    const txtIngsights = getText("homepage", "ingsights")
    const txtRatehour = getText("homepage", "ratehour")
    const txtInavg = getText("homepage", "inavg")
    const txtMatchrate = getText("homepage", "matchrate")
    const txtImprove = getText("homepage", "improve")
    const txtRecommendationsheader = getText("homepage", "recommendationsheader")
    const txtRatinggiven = getText("homepage", "ratinggiven")
    const txtRatingreceived = getText("homepage", "ratingreceived")
    const txtRatingreceiveLedger = getText("homepage", "ratingreceiveledger", [{ name: "AvgRat", value: "3.8" }])
    const txtAvtrating = getText("homepage", "avtrating")
    const txtKeepwarm = getText("homepage", "keepwarm", [{ name: "Name", value: "Tom" }])
    const txtPPhigher = getText("homepage", "pphigher", [{ name: "Higher", value: "4%" }])
    const txtPresented = getText("homepage", "presented")
    const txtOutoftop4 = getText("homepage", "outoftop4")
    const txtRatingbenchmarktocandidate = getText("homepage", "ratingbenchmarktocandidate")

    interface typeDashboardCardProps {
        header: string
        value: string
        footer: string
    }
    const DashboardCard = (props: typeDashboardCardProps) => {
        return (
            <Card sx={{ backgroundColor: "var(--find-bg-color-dashboard-card)", margin: 2, minWidth: "150px" }}>
                <Stack direction="column" alignItems="left" sx={{ margin: 1, }}    >
                    <Typography textAlign="left" variant="h4" color={"var(--find-text-color-dashboard-card)"} >{props.value}</Typography>
                    <Stack direction="column" alignItems="center" sx={{ margin: 1, }}    >
                        <Typography textAlign="center" variant="body2" color={"var(--find-text-color-dashboard-card)"}>{props.header}</Typography>

                        <Typography textAlign="center" fontSize="80%" variant="body2" color="var(--find-text-color-dashboard-card)" gutterBottom>{props.footer}</Typography>
                    </Stack>
                </Stack>
            </Card>
        )
    }

    return (
        <>
            <AccountMenu />



            <Card sx={{ display: 'center' }}>
                <Stack direction="column" margin={2} justifyContent='center' alignItems='flex'>
                    {/* ****************** */}
                    {/* Customer main part */}
                    {/* ****************** */}


                    <CardWithMinimize level={1} header={txtWorkAsCustomer() as string} type={isMedium() ? "list" : "stack"} defaultOpen={true}  >

                        {/* Notifications */}
                        <CustomerToDosComponent />

                        <Stack direction="column" alignItems="left" spacing={4} sx={{ margin: 2, display: 'flex' }} >

                            <ShowCustomerMenu />

                            <Stack direction="column" alignItems="left" sx={{ display: 'flex' }} >
                                {/* Insights */}

                                <CardWithMinimize iconName="dashboard" header={txtIngsights() as string} type="stack" defaultOpen={true}>
                                    <Grid container spacing={1}  >

                                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                            <DashboardCard value={"92,3%"} header={txtMatchrate() as string} footer={txtInavg() as string} />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                            <DashboardCard value={"€172,-"} header={txtRatehour() as string} footer={txtInavg() as string} />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                            <DashboardCard value={"4,5"} header={txtRatingreceived() as string} footer={txtInavg() as string} />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                            <DashboardCard value={"3,8"} header={txtRatinggiven() as string} footer={txtInavg() as string} />
                                        </Grid>
                                    </Grid>

                                </CardWithMinimize>

                                {/* Recommendations */}

                                <CardWithMinimize iconName="feedback" header={txtRecommendationsheader() as string} type="stack" defaultOpen={true}>

                                    <Stack direction="column" alignItems="left" sx={{ margin: 2, display: 'flex' }} >

                                        <Alert severity="info">{txtKeepwarm()}</Alert>
                                        <Alert severity="info">{txtPPhigher()}</Alert>
                                        <Alert severity="info">{txtRatingbenchmarktocandidate()}</Alert>

                                    </Stack>

                                </CardWithMinimize>

                            </Stack>

                        </Stack>


                    </CardWithMinimize>


                    {/* ******************* */}
                    {/* Candidate main part */}
                    {/* ******************* */}

                    <CardWithMinimize level={1} header={txtWorkAsCandidate() as string} type={isMedium() ? "list" : "stack"} defaultOpen={true}  >

                        {/* Notifications */}

                        <CandidateToDosComponent />


                        <Stack direction="column" alignItems="left" spacing={2} sx={{ margin: 2, display: 'flex' }} >

                            <ShowCandidateMenu />

                            <Stack direction="column" alignItems="left" sx={{ display: 'flex' }} >

                                {/* Recommendations */}
                                <CardWithMinimize iconName="feedback" header={txtRecommendationsheader() as string} type="stack" defaultOpen={true}>


                                    <Stack direction="column" alignItems="left" spacing={2} sx={{ margin: 2, display: 'flex' }} >

                                        <Card   >
                                            <Stack direction="column" alignItems="left" >
                                                <Alert sx={{ px: 1, py: 1 }} severity="info">{txtPresented()}
                                                    <Button >{txtImprove()}</Button>
                                                </Alert>
                                                <OrganizationCard
                                                    organizationName="Audi AG"
                                                    logoUrl={logoUrl1}
                                                    jobName="S/4 HANA Roll-out"
                                                    jobRole="Project Manager"
                                                    jobStartDate="Jun 2020" jobEndDate="Dec 2023"
                                                    onSiteText="5 days onsite"
                                                    matchPct={98}
                                                    locationCountry="DE"
                                                    locationCity="Münich"
                                                />

                                            </Stack>

                                        </Card>


                                        <Card   >
                                            <Stack direction="column" alignItems="left" >
                                                <Alert sx={{ px: 1, py: 1 }} severity="warning">{txtOutoftop4()}
                                                    <Button >{txtImprove()}</Button>
                                                </Alert>
                                                <OrganizationCard
                                                    organizationName="Coca-cola"
                                                    logoUrl={logoUrl2}
                                                    jobName="World wide Concur roll-out"
                                                    jobRole="Project Manager"
                                                    jobStartDate="Jun 2020" jobEndDate="Dec 2021"
                                                    onSiteText="3 days onsite/2 days remote"
                                                    matchPct={87.3}
                                                    locationCountry="DK"
                                                    locationCity="Copenhagen"
                                                />

                                            </Stack>

                                        </Card>

                                        <Card sx={{ margin: 2, display: 'flex' }}>

                                            <CandidatePings />

                                        </Card>
                                    </Stack>

                                </CardWithMinimize>
                            </Stack>
                        </Stack>


                    </CardWithMinimize>

                </Stack >

            </Card >



        </>

    );
}

export { HomePage }