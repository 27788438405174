import { For, onMount, createSignal, Show } from "solid-js";
import { Typography, Popover, Avatar, Divider, Stack, FormControlLabel, Switch } from "@suid/material";
import { getURL } from '../../../utils/fetchAPI'
import { showError } from '../SmallComponents/SmallShowError'
import { getText } from './Translate' 

const apiUrl = import.meta.env.VITE_APP_API_URL; 

type IconData = {
    Name: string;
    PingedBy: string;
    logoUrl: string;
    Disabled: boolean;
};

type IconDataList = {
    status: string,
    redirect: string,
    iconData: IconData[],
}

const CandidatePing = ({ iconData }: { iconData: IconData }) => {
    const [character, setCharacter] = createSignal(iconData);
    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const handlePopoverOpen = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = () => Boolean(anchorEl());
    const txtNoLogo = getText("pingcandidate", "noLogo")
    const txtFrom = getText("pingcandidate", "from")
    const txtPingName = getText("pingcandidate", "pingedYou")

    return (
        <>
            <Typography
                aria-owns={open() ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {/* ({character()?.Disabled}==true ? disabled) */}
                <Avatar
                    sx={{
                        width: 60, height: 60, px: 1, py: 1, margin: 1,
                        bgcolor: 'background.default'[50],
                    }}
                    src={iconData.logoUrl}
                    variant="rounded"
                    onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}
                >
                    {txtNoLogo()}
                </Avatar>

            </Typography>
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: "none" }}
                open={open()}
                anchorEl={anchorEl()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >

                <Typography sx={{ p: 1 }}> {character()?.PingedBy} {txtFrom()} {character()?.Name}  {txtPingName()}</Typography>

            </Popover>

        </>
    )
};

const CandidatePings = () => {
    const [autoconfirmedEnabled, setAutoconfirmedEnabled] = createSignal(true);
    const [categories, setCategories] = createSignal<IconData[]>([]); // createSignal for categories

    const handleSwitchChange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        setAutoconfirmedEnabled(target.checked);
    };
    const txtError = getText("pingcandidate", "error")
    onMount(async () => {
        try {
           // const fetchedData: IconDataList = await getURL(`${apiUrl}/candidate/pings`, 'GET', null);
            const fetchedData: IconDataList = await getURL(`${apiUrl}/candidate/pings`, 'GET', null);
            fetchedData.status === '200' ? setCategories(fetchedData.iconData) : showError(txtError() as string, `${fetchedData.status}`);
        } catch (error) {
            showError(txtError() as string, `${error}`);
        }
    });
    const txtPingsWaiting = getText("pingcandidate", "pingsWaiting")
    const txtLatestPings = getText("pingcandidate", "latestPings")
    const txtAuto = getText("pingcandidate", "autoConfirm")
    const txtLoading = getText("pingcandidate", "loading")

    return (
        <>
            <Stack
                direction="column"
                sx={{ px: 2, py: 1, bgcolor: 'background.default'[50] }}
            >
                <Show
                    when={autoconfirmedEnabled()}
                    fallback={
                        <Typography variant="h6">{txtPingsWaiting()}</Typography>
                    }
                >
                    <Typography variant="h6">{txtLatestPings()}</Typography>
                </Show>
                <Divider flexItem />
                <FormControlLabel
                    control={
                        <Switch id="autoconfirmedEnabled" defaultChecked
                            onChange={handleSwitchChange}
                        />
                    }
                    label={txtAuto()}
                    sx={{ px: 2 }}
                />
                <Divider flexItem />
                <Stack
                    direction="row"
                    alignItems="center"

                    sx={{ px: 2, py: 1, bgcolor: 'background.default'[50] }}
                >
                    {/*  <Divider orientation="vertical" flexItem /> */}

                    <For
                        each={categories()}
                        fallback={<p>{txtLoading()}</p>}
                    >
                        {(character) => <CandidatePing iconData={character} />}
                    </For>

                </Stack>
            </Stack>
        </>
    )
}

export { CandidatePings }