import { Stack  } from "@suid/material"; 
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { NotificationListItem } from '../SmallComponents/SmallNotificationList'
import { MatchingIcon, ContractIcon, ProposalIcon, InterviewIcon}  from '../icons'
 
import { getText } from '../SmallComponents/Translate'


const CandidateToDosComponent = () => {
    const txtHeader = getText("todopage", "header")
    const txtContractsreadytosign = getText("homepage", "contractsreadytosign", [{ name: "Number", value: "1" }])
    const txtProsalsready = getText("homepage", "prosalsready", [{ name: "Number", value: "1" }])
    const txtUpcomminginterviews = getText("homepage", "upcomminginterviews", [{ name: "Number", value: "2" }])
    const txtRecommendations = getText("homepage", "recommendations", [{ name: "Number", value: "2" }])
    
    return (
        <>
            {/* List of all users to-dos accross all groups*/}
            <Stack >

                <CardWithMinimize header={txtHeader() as string}  type="list" defaultOpen={true}>

                    <NotificationListItem route="/candidatetodo" secondary="Meta Inc" notificationIcon={<ContractIcon />} >{txtContractsreadytosign()}</NotificationListItem>
                    <NotificationListItem route="/candidatetodo" secondary="Audi AG" notificationIcon={<ProposalIcon />} >{txtProsalsready()}</NotificationListItem>
                    <NotificationListItem route="/candidatetodo" secondary="Mærsk 12/4 13:00, Apple 12/4 16:00" notificationIcon={<InterviewIcon />} >{txtUpcomminginterviews()}</NotificationListItem>
                    <NotificationListItem route="/candidatetodo" secondary="Audi AG, Coca-Cola" notificationIcon={<MatchingIcon />} showDivider={false} >{txtRecommendations()}</NotificationListItem>
                   
                </CardWithMinimize>

            </Stack>

        </>
    );
}
export { CandidateToDosComponent }